.display-none-class{
display:none !important;
}

.next-icon{
width:40px;
height:100%;
max-width: 40px;
min-width:30px;
background-color: #eecf3b;

    @include mqMobile{
        font-size:18px;
        max-width:20px;
        min-width:20px;
      }

    @include mqMobileMedium{
        max-width:30px;
        min-width:30px;
      }


    @include mqSmallScreen{
        font-size:18px;
        max-width:30px;
        min-width:30px;
      }

   
}

.firstLayer{
    left:0;
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    z-index: 10000;

    @include mqMobile{
        top: 125px;
        height: 80%;
      }

      @include mqMobileMedium{
        height: 85%;
      }

      @include mqTablet{
       
        width:50%;
        right:0;
        left:unset;
        top:100px
      }

      @include mqLargeScreen{
       
       height: 90%;
      }
}
.text-typo-helper{
    letter-spacing: .1em;
font-size:10px;
font-weight: 500;
margin-bottom:0 !important;

 background-color: color(dark);

    

    padding: 10px;
    display: table-cell;
    vertical-align: middle;
    color: white;
    height: 40px;
    border: 1px solid #eecf3b;

    @include mqMobile{
        font-size:9px;
      }

      @include mqMobileMedium{
        font-size:10px;
      }

    @include mqSmallScreen{
        font-size:10px;
      }
}
.container-layer{
    right: 0;
    top: 600px;
    background: none;
    position: absolute;
    box-shadow: 1px 1px 1px 1px #202525;
    border-radius: 4px;
}
.content-wrapper-layer{
display:flex;
background:white;
}
.section{

    &.thematic-trips--section{
        .thematic-trips__item{

            margin-bottom: $padding-smaller;

            @include mqSmallScreen{
                &:not(:last-of-type){
                    margin-bottom: $padding-smaller;
                }
            }
           
        }
    }
}

#menu-section {
    .flex-direction {
        flex-direction: column;
    }
}

#custom-flip-book {

    .sch-wrapper{
        z-index: 10;
        position: absolute;
        bottom:60px;
        right: 15px;
display: flex;


.helper-wrapper{
display:flex
}
@include mqMobile{
    bottom: 30px;
  }

  
  @include mqTablet{
   bottom:50px;
}

@include mqLargeScreen{
       
    bottom:60px;
   }

.sch-layer{
    cursor: pointer;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.8) 150%);
    top: 0;
    position: absolute;
    width: 100%;
   
   
    @include mqTablet{
        width: 50%;
        top: 0;
        right: 10px;
    }
    p{
        padding-left: 50px;
        text-align: center;
        font-size:22px;
        display: table-cell;
        vertical-align: middle;
        color:white;
  
    }
}
    }

    .stf__block {
        background-color: color(dark);
     
        // @include box-shadow(-1px -1px 2px rgba(36, 10, 3, .05), 0 1px 1px white inset);
        p {
            width: 100%;
            height: 100%;
            img {
                // @include box-shadow(-1px -1px 2px rgba(36, 10, 3, .05), 0 1px 1px white inset);
                //width: 100%;
                height: 100%;
                width:100%;
            }
        }
    }
}

.lemos-img-position{
object-position: center -210px;
}

.wizard{
display:none;
}

.kisfaludy-trick-sticky-navbra{
height:10vh;
background: linear-gradient(0deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.8) 150%);
display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
 justify-content: center;
    align-items: center;

p{
    font-size: 25px;
color:white;
text-align: center;
}
}