.nopadding {
    padding: 0;
}
.py-6 {
    padding-top: 6em !important;
    padding-bottom: 6em !important;
}

.py-7 {
    padding-top: 7em !important;
    padding-bottom: 7em !important;
}

.py-8 {
    padding-top: 8em !important;
    padding-bottom: 8em !important;
}

.pt-6 {
    padding-top: 6em !important;
}

.pt-7 {
    padding-top: 7em !important;
}

.pt-8 {
    padding-top: 8em !important;
}

.pb-6 {
    padding-bottom: 6em !important;
}

.pb-7 {
    padding-bottom: 7em !important;
}

.pb-8 {
    padding-bottom: 8em !important;
}

@media (min-width: 768px) {
    .py-md-6 {
        padding-top: 6em !important;
        padding-bottom: 6em !important;
    }

    .py-md-7 {
        padding-top: 7em !important;
        padding-bottom: 7em !important;
    }

    .py-md-8 {
        padding-top: 8em !important;
        padding-bottom: 8em !important;
    }

    .pt-md-6 {
        padding-top: 6em !important;
    }

    .pt-md-7 {
        padding-top: 7em !important;
    }

    .pt-md-8 {
        padding-top: 8em !important;
    }

    .pb-md-6 {
        padding-bottom: 6em !important;
    }

    .pb-md-7 {
        padding-bottom: 7em !important;
    }

    .pb-md-8 {
        padding-bottom: 8em !important;
    }
}

@media (min-width: 1200px) {
    .py-lg-6 {
        padding-top: 6em !important;
        padding-bottom: 6em !important;
    }

    .py-lg-7 {
        padding-top: 7em !important;
        padding-bottom: 7em !important;
    }

    .py-lg-8 {
        padding-top: 8em !important;
        padding-bottom: 8em !important;
    }

    .pt-lg-6 {
        padding-top: 6em !important;
    }

    .pt-lg-7 {
        padding-top: 7em !important;
    }

    .pt-lg-8 {
        padding-top: 8em !important;
    }

    .pb-lg-6 {
        padding-bottom: 6em !important;
    }

    .pb-lg-7 {
        padding-bottom: 7em !important;
    }

    .pb-lg-8 {
        padding-bottom: 8em !important;
    }
}