// BREAKPOINTS
$breakpoint-mobile: 312px;
$breakpoint-mobile-small: 370px;
$breakpoint-mobile-medium: 400px;
$breakpoint-mobile-big: 512px;
$breakpoint-tablet: 768px;
$breakpoint-small-screen: 991px;
$breakpoint-medium-screen: 1024px;
$breakpoint-tiny-screen:1140px;
$breakpoint-xlarge-screen:1200px;
$breakpoint-large-screen: 1280px;
$breakpoint-xl-screen: 1680px;
$breakpoint-xxl-screen: 1920px;

// TEXTS
$textsize-title-xxl: 3.2em;
$textsize-title: 40px;
$textsize-title-medium: 2rem;
$textsize-subtitle: 1.8em;
$textsize-lead: 1.2em;
$textsize-body: 16px;
$textsize-compact: .9em;

// PADDINGS
$padding-tiny: 10px;
$padding-smaller: 16px;
$padding-small: 24px;
$padding-medium: 32px;
$padding-big: 46px;
$padding-huge: 64px;
$padding-xl: 80px;
$padding-xxl: 96px;