.events--section {
    .events__events-wrapper {
        width: 100%;
        .event-item {
            align-self: center;
            height: 300px;
            width: 100%;
            max-height: 300px;
            margin: 0;
            max-width: 550px;
            overflow: hidden;

            &:not(:last-of-type) {
                margin-bottom: 20px;
            }

            @include mqTablet {
                max-height: unset;
                max-width: 500px;
                height: 460px;

                padding: 8px;

                &:not(:last-of-type) {
                   // margin-right: 20px;
                    margin-bottom: 0;
                }
            }            

            @include mqSmallScreen {
                max-height: 460px;
                max-width: 400px;
            }

            @include mqMediumScreen{
                max-width: 460px;
            }

            @include mqXLScreen{
                max-width: 400px;
            }

            &:hover {
                .event-item__content {
                    .event__image {
                        img {
                            transform: scale(1.05);
                           // filter: blur(1.5px) grayscale(0.3);
                           filter: grayscale(0.3);
                           -webkit-backdrop-filter:saturate(180%) blur(2px);
backdrop-filter: saturate(180%) blur(2px);
                        }
                    }
                    //.event__text-container {
                      //  &::before{
                      //      height: 100%;
                     //       opacity: .75;
                      //      transition: opacity .3s ease-in-out;
                    //    }

                        .event__hidden-textgroup {
                            height: 75px;  

                            @include mqSmallScreen{
                                height: 100px;
                            }   
                            
                            @include mqLargeScreen{
                                height: 75px;
                            }
                            
                            hr {
                                width: 70px;
                                opacity: 1;
                            }
                            .event__bodytext {
                                display: block;
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .event-item__content {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                position: relative;
                height: 100%;

                .event__image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    transition: height 0.3s ease-in-out;
                    z-index: -1;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba($color: color(black), $alpha: 0.35);
                    }

                    > img {
                        object-fit: cover;
                        object-position: center;
                        height: 100%;
                        width: 100%;
                        transform: scale(1);
                        //filter: blur(0) grayscale(0);
                        filter: grayscale(0);
                        -webkit-backdrop-filter:saturate(0) blur(0);
backdrop-filter: saturate(10) blur(0);
                        transition: transform ease-in-out 0.3s, filter ease-in-out 0.3s;
                    }
                }

                .event__text-container {
                    position: relative;
                    width: 100%;
                    padding: 20px;
                    transition: all 0.3s ease-in-out;

                    &::before{
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 0;
                        
                       // background:linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0, 0, 0,0) 100%);
                        z-index: -1;
                        opacity: 0;
                        transition: opacity .15s ease-in-out, height .35s ease-in-out;
                    }

                    .event__title,
                    .event__bodytext {
                        &:hover {
                            text-decoration: none;
                        }
                    }

                    .event__title {
                        text-align: center;
                        font-weight: 700;
                        padding: 0;
                        margin: 0;
                        color: color(light);
                        transition: all 0.3s;
                        text-shadow: 0px 1px 7px rgba($color: #000000, $alpha: .8);
                    }

                    .event__hidden-textgroup {
                        height: 0;
                        display: block;
                        overflow: hidden;
                        transition: .3s height ease-in-out .15s;

                        hr {
                            margin: 12px auto;
                            width: 0;           
                            padding: 0;
                            opacity: 0;
                            transition: opacity 0.3s  ease-in-out .2s, width 0.6s ease-in-out;
                        }

                        .event__bodytext {
                            text-align: center;
                            font-weight: 300;
                            padding: 0;
                            margin: 0;
                            opacity: 0;
                            color: color(light);
                            transition: opacity 0.3s ease-in-out .12s;
                        }
                    }
                }
            }

            &.event--eskuvo {
            }
            &.event--ceges {
            }
            &.event--szuletesnap {
            }
            &.event--foto {
            }
        }
    }


// .event-description-container.selected {
//     display: flex;
// }

// .event-description-container {
//     display: none;
//     flex-direction: column;
//     width: 100%;
// }

// .event-description-pic {
//     width: 100%;
//     height: 250px;
//     overflow: hidden;
//     position: relative;
//     display: block;
// }

// .event-description-pic img {
//     position: relative;
//     display: block;
//     top: 50%;
//     left: 50%;
//     transform: translateX(-50%) translateY(-50%);
//     width: 100%;
//     height: auto;
// }

// @media (min-width: 768px) {
//     .event-description-pic {
//         height: 360px;
//     }

//     .event-description-pic::after {
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         display: block;
//         width: 100%;
//         height: 100%;
//         background-size: cover;
//         background-position: center;
//     }

//     /*Event pics*/

// }

// .event-description-text {
//     padding: 32px 0;
//     line-height: 1.6em;
// }

// .event-description-text > .event-title > h2 {
//     font-size: 2em;
//     font-weight: bold;
//     padding-bottom: 12px;
//     margin: 0;
// }

// .event-description-text > p {
//     padding: 0;
//     margin: 0;
// }

// .event-details {
//     font-size: 1em;
//     line-height: 1.2em;
//     padding-top: 32px;
// }

// .event-details > h4 {
//     padding: 0 0 10px 0;
//     margin: 0;
//     font-weight: bold;
// }

// .event-details > h5 {
//     padding: 0 0 6px 0;
//     margin: 0;
// }

// .event-details > p {
//     line-height: 1.6em;
// }

// .event-details > p:last-of-type {
//     margin-bottom: 10px;
// }

// .event-list {
//     padding-top: 16px;
// }

// .event-list li > a {
//     color: unset;
//     text-decoration: none;
// }

// @media (min-width: 768px) {
//     .event-list {
//         padding-top: 0;
//     }
// }