.hero-section {
    &.hero--home {
        .hero__logo {
            margin: 0 auto $padding-small auto;
            margin-bottom: $padding-small;

            @include mqSmallScreen {
                margin-bottom: 0;
            }
        }

        .hero__text {
            .hero__title,
            .hero__subtitle {
                text-align: center;

                @include mqSmallScreen {
                    text-align: left;
                }
            }
            hr {
                margin-left: auto;
                margin-right: auto;

                @include mqSmallScreen {
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            .btn.hero-CTA {
                display: block;
                margin: $padding-medium auto 0 auto;
                max-width: 200px;

                @include mqSmallScreen {
                    margin: $padding-medium 0 0 0;
                }
            }
        }
    }
}

.introduction--section {
    padding-top: 0;
    padding-bottom: 0;
}

@media (min-width: 768px) {
    .introduction--section {
        padding-top: 3em;
        padding-bottom: 3em;
    }
}

// Map Section
.map--section {
    padding-top: $padding-big;
    padding-bottom: $padding-big;

    @include mqSmallScreen {
        padding-top: $padding-xl;
    }

    .map-section__map-container {
        display: block;

        @include mqTablet {
            padding-bottom: 0;
        }

        > iframe {
            width: 100%;
            height: 420px;
        }
    }
    .map-section__notification {
        span {
            margin-bottom: 0;
            margin-top: 6px;
        }
    }

    .map-section__text {
        @include mqSmallScreen {
            margin-top: -42px;
        }
    }
}

// Departures section
.departures--section {
    padding-top: 0;
    padding-bottom: $padding-big;
    position: relative;

    @include mqTablet {
        padding-bottom: $padding-huge;
    }

    @include mqSmallScreen {
        padding-bottom: $padding-xl;
    }

    .departures__img-container {
        position: relative;

        .departures__img {
            position: relative;
            display: block;
            height: auto;
            width: 100%;
            object-fit: cover;
            object-position: center;
            max-height: 420px;
            max-width: unset;

            @include mqSmallScreen {
                object-position: 50% 75%;
            }
        }
    }
}

// Ship route
.ship-route--section {
    padding: $padding-big 0;
    position: relative;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        left: -65px;
        top: -180px;
        width: 420px;
        height: 420px;
        background-image: url("../../img/graphic_assets/decor-lines-A.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: -1;
        transform: rotate(-36deg);
        opacity: 0.075;
    }

    @include mqSmallScreen {
        padding: $padding-xl 0;

        &::after {
            left: -120px;
            top: -250px;
            width: 650px;
            height: 650px;
        }
    }

    .ship-route__map {
        height: 100%;
        width: 100%;

        @include mqSmallScreen {
            padding-right: 28px;
        }

        > img {
            object-fit: cover;
            box-shadow: -35px -10px 40px rgba($color: #5e5c64, $alpha: 0.07);
        }
    }

    .ship-route__text {
        .ship-route__subtitle {
            font-size: $textsize-title-medium;
            color: color(dark);
            line-height: 140%;
            margin-bottom: 0;
            padding-bottom: $padding-small;
        }

        .ship-route__list {
            > li {
                display: flex;
                width: 100%;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: flex-start;
                padding-bottom: $padding-medium;

                @include mqSmallScreen {
                    flex-direction: row;
                    flex-wrap: nowrap;
                }

                > i {
                    flex-shrink: 0;
                    align-self: center;
                    display: block;
                    position: relative;
                    width: 50px;
                    height: 50px;
                    margin-right: 0 auto;
                    margin-bottom: 10px;

                    &:first-of-type {
                        margin-top: 10px;
                    }

                    @include mqSmallScreen {
                        margin-right: $padding-small;

                        &:first-of-type {
                            margin-top: 0;
                        }
                    }

                    &.route-point {
                        &::after {
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            display: block;
                            background-repeat: no-repeat;
                            background-size: contain;
                        }

                        &.coffee {
                            &::after {
                                background-image: url(../../img/graphic_assets/route-icon-coffee.svg);
                            }
                        }

                        &.photo {
                            &::after {
                                background-image: url(../../img/graphic_assets/route-icon-photo.svg);
                            }
                        }

                        &.wine {
                            &::after {
                                background-image: url(../../img/graphic_assets/route-icon-wine.svg);
                            }
                        }

                        &.gramophone {
                            &::after {
                                background-image: url(../../img/graphic_assets/route-icon-gramophone.svg);
                            }
                        }
                    }
                }

                > p {
                    text-align: center;
                    font-size: $textsize-body;
                    color: color(dark);
                    display: block;
                    width: 100%;

                    @include mqSmallScreen {
                        text-align: left;
                    }
                }
            }
        }
    }
}

// Infos section
.info--section {
    position: relative;
    padding-top: 5em;
}

// Tickets section
.tickets--section {
    // Header legyen teljes box szélességű
    .icon-wrapper-info {
        margin: auto 15px auto 10px;
        display: block;
        height: 100%;
        width: 20px;
        min-width: 25px;
        .icons-class-info {
            width: 25px;
        }

        &.weather-block {
            margin: 0 auto;
        }
    }

    .schedule__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 97px;

        &.title-heights {
            height: 89px;
        }
        .absolute-title {
            z-index: 10;
            position: absolute;
            right: -50px;
        }
        > h3 {
            line-height: 140%;
        }
        > span {
            font-size: $textsize-compact;
            line-height: 100%;

            &.time-row {
                font-size: 0.8em;
                font-weight: 200;
                margin: 0 10px;
            }
            &.subtitle-span {
                font-size: 1em;
                margin-bottom: 5px;
            }
        }
    }

    .schedule-timetable {
        .description-span {
            font-size: 10px;
        }
        > .schedule-notification {
            width: 100%;
            padding: 18px 18px !important;
            background-color: color(primary);

            > p {
                font-size: 16px;
                color: color(dark);
                display: block;
                position: relative;
                line-height: 140%;
                padding-left: 35px;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 25px;
                    width: 25px;
                    background-image: url(../../img/icons/warn.svg);
                    background-size: contain;
                }
            }
        }

        > .schedule-timetable__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            width: 100%;
            padding: 20px 0 20px 0;
            height: 70px;

            &.standard-padding-titles {
                @include mqTablet {
                    padding: 20px 0 20px 15px;
                }
            }

            .icons-class {
                color: color(dark);
                padding-left: 25px;
                padding-right: 10px;
                width: 20%;
                display: flex;
                justify-content: center;

                &:before {
                    border-radius: 50%;
                    border: 2px solid black;
                    padding: 6px;
                }

                &.info {
                    &:before {
                        border: none;
                    }
                }
            }

            .icon-wrapper {
                width: 30px;
                justify-content: flex-end;
                height: 100%;
                display: flex;
                margin-left: 10px;
                margin-right: 10px;
                .icons-class {
                    padding-left: 0px;
                    padding-right: 0px;

                    display: flex;
                    justify-content: center;
                    margin-bottom: auto;

                    width: 30px;

                    &:before {
                        border-radius: 50%;
                        border: 2px solid black;
                        padding: 6px;
                    }

                    &.info {
                        &:before {
                            border: none;
                        }
                    }
                }
            }
            &.subtitle-table {
                display: flex;
                flex-direction: column;
            }
            &:nth-of-type(even) {
                background-color: rgba($color: color(lighter), $alpha: 0.45);
            }

            &:last-of-type {
                //  margin-bottom: 20px;
            }

            > .schedule__time {
                //width: 55%;
                width: 100%;

                display: block;
                text-align: center;
                font-size: $textsize-lead;
                font-weight: bolder;
                color: color(dark);

                &.text-orientation {
                    text-align: center;
                    font-weight: 800;
                    font-size: 1.3rem;
                }

                &.text-orientation-wo-pd {
                    text-align: left;
                    width: 80%;

                    &.text-container-types {
                        height: 100%;
                        width: 90%;
                        flex-direction: column;
                        display: flex;
                        .subtext-types-wrapper {
                            position: relative;
                            top: -2px;
                            line-height: 12px;
                        }
                        .subtext-types {
                            font-size: 12px;
                        }
                        .subtext-types2 {
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }
                }
            }

            > .special-route-icons {
                width: 45%;
                padding-left: 12px;
                height: 10px;
                line-height: 0;

                > i {
                    position: relative;
                    opacity: 100%;
                    display: block;
                    width: 42px;
                    height: 42px;
                    content: none;

                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        width: 42px;
                        height: 42px;
                        background-color: color(primary);
                        border-radius: 100%;
                        z-index: 0;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 28px;
                        height: 28px;
                        transform: translateX(-50%) translateY(-50%);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        z-index: 1;
                    }

                    &.wine-glass {
                        &::after {
                            background-image: url(../../img/icons/wine-glasses.svg);
                        }
                    }
                }
            }
        }

        // > .schedule-column {
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        //     min-height: 135px;
        //     position: relative;
        //     border: 1px solid rgba($color: color(light), $alpha: 0.6);

        //     &.schedule-column--empty {
        //         overflow: hidden;
        //         &::before {
        //             content: "";
        //             position: absolute;
        //             top: 50%;
        //             left: -25%;
        //             display: block;
        //             transform: rotate(45deg);
        //             transform-origin: center;
        //             width: 150%;
        //             border-bottom: solid 2px rgba($color: color(light), $alpha: 0.6);
        //         }

        //         &::after {
        //             content: "";
        //             position: absolute;
        //             top: 50%;
        //             left: -25%;
        //             display: block;
        //             transform: rotate(-45deg);
        //             transform-origin: center;
        //             width: 150%;
        //             border-bottom: solid 2px rgba($color: color(light), $alpha: 0.6);
        //         }
        //     }
        // }
    }

    .schedule-timetable .schedule-header span {
        vertical-align: middle;
        padding-right: 5px;
    }

    .schedule-timetable .schedule-time {
        text-align: center;
        -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch;
        font-size: 1.65em;
        font-weight: 600;
        color: black;
    }

    /*schedule details / icons */

    .schedule-timetable .schedule-details {
        display: block;
        width: 100%;
        font-size: 0.8em;
        line-height: 120%;
        padding: 10px 15px;
        text-align: center;
    }

    .schedule-timetable .schedule-details span {
        display: inline-block;
        vertical-align: middle;
        opacity: 0.5;
    }

    .schedule-timetable .icon:not(:only-child) {
        padding-right: 5px;
    }

    .schedule-timetable .icon {
        height: 0.65em;
        opacity: 0.4;
    }

    .schedule-timetable .icon.pirate-flag,
    .schedule-timetable .icon.moon,
    .schedule-timetable .icon.sunset {
        height: 0.5em;
    }
}

.title-heights {
    .tickets-title {
        font-size: 25px !important;
        font-weight: 600 !important;
        letter-spacing: 2px;
    }
}
// Ticket box
.tickets-box {
    .boxed {
        padding-bottom: 5px;
    }
    .tickets__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 97px;

        &.title-heights {
            height: 89px;
        }
        h3 {
            &.tickets-title {
                letter-spacing: 2px;
                font-size: 25px !important;
                font-weight: 600;
            }
            width: 100%;
        }
    }

    .tickets-block {
        width: 100%;

        .tickets-block__item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            padding: 20px 0;

            &.tickets-btn-container {
                border-top: 0 !important;
                padding-top: 0;
            }
            .info-wrapper {
                display: flex;
                flex-direction: row;
                .img-wrapper {
                    width: 60px;
                    margin: 0 12px 12px 12px;
                }
                .info-title {
                    font-weight: bold;
                    padding: 0;
                    margin: 0;
                    display: inline-block;
                    vertical-align: middle;
                    color: rgba(0, 0, 0, 0.87);
                }
                .img-info {
                    width: 20px;
                }

                .info-text {
                    font-weight: 600;
                    color: color(dark);
                    font-size: 12px;
                    margin-top: 5px;
                }
            }
            &:not(:first-of-type) {
                border-top: 1px solid color(lighter);
            }

            > .ticket__details {
                display: block;
                width: 75%;
                font-size: $textsize-body;

                &.ticket__sub-param{
                    margin-top: 12px;                      
                    h4{
                        margin: 0;
                        font-size: $textsize-body;
                    }        
                    .ticket__addendum{
                        margin-top: 4px;
                    }          
                }

                .plus-padding-top {
                    padding-top: 4px;
                }
                &.wine-boat {
                    i {
                        position: relative;
                        width: 42px;
                        height: 42px;
                        display: inline-block;
                        padding: 0;
                        margin: 0 6px 0 0;
                        vertical-align: middle;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: color(primary);
                            border-radius: 100%;
                        }

                        &::after {
                            content: "";
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-image: url(../../img/icons/wine-glasses.svg);
                            background-size: 30px 30px;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                    }
                }

                > .ticket__title {
                    font-size: $textsize-lead;
                    font-weight: bold;
                    padding: 0;
                    margin: 0;
                    display: inline-block;
                    vertical-align: middle;
                }
                
            }

            > .ticket__price {
                width: 25%;
                >h3, >h4{
                    text-align: right;
                }
                > h3 {
                    text-align: right;
                    font-size: $textsize-lead;
                    font-weight: bold;
                    margin: 0;
                    padding: 0;
                }

                &.ticket__sub-param{
                    margin-top: 12px;
                    h4{
                        font-size: $textsize-body;
                    }
                }
            }

            > .ticket__description {
                width: 100%;
                padding-top: 12px;
            }
        }
    }
}

// Programs section
.programs--section {
    .programs__title {
        margin-bottom: $padding-medium !important;
    }

    .program {
        margin-top: 0;
        margin-bottom: $padding-medium;
        padding-bottom: 22px;

        @include mqSmallScreen {
            margin-bottom: 0;
        }

        &:last-child {
            margin-bottom: 3em;

            @include mqTablet {
                margin-bottom: 0;
            }
        }

        span.program__icon {
            display: block;
            position: absolute;
            top: 32px;
            left: 0;
            height: 60px;
            width: 110px;
            background-color: color(primary);

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                height: 44px;
                width: 44px;
            }

            &.borhajo::after {
                background-image: url(../../img/icons/wine-glasses-white.svg);
            }

            &.naplemente-tura::after {
                background-image: url(../../img/icons/sunset-white.svg);
            }
        }

        .program__header {
            padding-bottom: 45px;
            padding-left: 90px;

            @include mqTablet {
                padding-left: 80px;
            }

            span {
                display: inline-block;
                vertical-align: middle;
                margin-bottom: 0;
                line-height: 100%;
            }
        }

        .program__footer-wrapper {
            margin-top: 6px;
            width: 100%;

            hr {
                margin: 20px 0;
            }

            .program__footer-item-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin: 0;
                padding: 0;

                .program__footer-item {
                    margin: 0;
                    padding: 0;
                    width: 50%;

                    &:last-of-type {
                        text-align: right;
                    }

                    &.header-item {
                        flex-basis: 100%;
                        padding-bottom: 4px;
                        line-height: 100%;
                    }
                }
            }
        }

        .program-notice {
            height: 30px;
            padding: 10px;
            display: block;
            background-color: color(primary);
            margin-bottom: 10px;

            .p {
                padding: 0;
                line-height: 10px;
                color: black;
            }
        }
    }
}

// Video section
.video--section {
    padding: 0;
    line-height: 0;
    position: relative;

    &.videoplaying {
        .video__container::after {
            opacity: 0;
        }

        .video__preview {
            display: none;
        }
    }

    .video__container {
        &::after {
            content: "";
            position: absolute;
            background-color: black;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: block;
            opacity: 0.65;
            transition: opacity 0.3s;
            z-index: 5;
        }

        .video__preview {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 5;

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                display: block;
                background-image: url(../../img/video-preview-01.jpg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                transition: opacity 0.3s;
            }

            &.hiding {
                display: none;
                opacity: 0;

                &::after {
                    content: none;
                }
            }
        }

        video {
            width: 100%;
            height: 100%;
            z-index: 0;
            position: relative;
        }
    }

    .video__title-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        text-align: center;
        z-index: 10;

        > .video__title {
            color: white;
        }

        > .video__btn {
            width: 64px;
            height: 64px;
            top: 0;
            left: 0;
            transition: all 0.5s ease;
            display: block;
            z-index: 1;
            transform-origin: center;
            transform: scale(1);
            opacity: 1;

            @include mqSmallScreen {
                width: 80px;
                height: 80px;
            }

            &.hiding {
                opacity: 0;
                transform: scale(0.7);
            }

            &.play {
                background-image: url(../../img/icons/play-button-primary-col.svg);
                top: 0;
                left: 0;
                opacity: 1;
            }
        }
    }
}

// Slider section
@media (max-width: 990px) {
    .flickity-prev-next-button {
        display: initial;
    }
}

.gallery--section {
    padding: 0;
    padding-top: 0 !important;
    margin: 0;
}
.image-slider {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

[class*="slide__"] {
    width: 100%;
    max-width: 650px;
    position: relative;
}

[class*="slide__"] .img-container {
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
    display: block;
}

[class*="slide__"] .img-container img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 100%;
    width: auto;
    -o-object-fit: cover;
    object-fit: cover;
}

[class*="slide__"] img:not(:last-child) {
    padding-right: 1em;
}

// FAQ section
.faq--section {
    .accordion-title-margin {
        margin: 0 20px;
    }

    .faq__img {
        height: 700px;
        width: 100%;
        //position: relative;

        > img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    .accordion.ui.fluid.styled {
        @include size(max, tablet, xxxmedium) {
            margin-bottom: 20px;
        }

        .accordion-text-margin {
            margin: 0 5px 0 50px;
        }
    }

    .accordion .accordion__title {
        position: relative;
        //  opacity: 0.65;
    }
    .accordion__title,
    .accordion li:last-child .accordion__title {
        border-color: black;
    }

    .accordion .accordion__title:before {
        content: "\e80c";
    }

    .accordion__title span,
    .accordion-1 li.active .accordion__title span,
    .accordion .accordion__title:before,
    .accordion-1 li.active .accordion__title:before {
        color: black;
    }

    .accordion-1 li.active .accordion__title {
        background-color: color(primary);
        border-color: black;
    }

    .accordion__title:before {
        position: absolute;
        left: 10px;
        top: 7px;
    }

    .accordion__title span {
        margin-left: 12px;
    }

    .question:last-child .accordion .accordion__title {
        margin-bottom: initial;
    }

    .question-row {
        @include mqSmallScreen {
            &.col-12 {
                padding-left: 2px;
            }
        }
    }

    .question {
        @include mqSmallScreen {
            &.col-12 {
                padding-left: 1px;
            }
        }
    }

    @media (max-width: 768px) {
        .faq--section .question:last-child {
            padding-bottom: 2em;
        }

        .question:last-child .accordion .accordion__title {
            margin-bottom: 0;
        }
    }
}

// Form section
.form--section:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;

    background-size: cover;
    background-position: center;
    opacity: 0.08;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

// Email section
.email--section {
    position: relative;
    background-color: color(dark);
    margin: 0;
    padding: 0;
    padding-top: 0 !important;
    width: 100%;

    @include mqSmallScreen {
        overflow: hidden;

        &::after {
            content: "";
            position: absolute;
            top: -130px;
            right: -140px;
            width: 650px;
            height: 350px;
            background-image: url(../../img/graphic_assets/decor-lines-A.svg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            transform: rotate(35deg);
            opacity: 0.65;
        }
    }

    .email-section__bckgrnd-img-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba($color: #000000, $alpha: 0.75);
        }

        @include mqSmallScreen {
            position: relative;
            height: auto;

            &::after {
                background-color: transparent;
            }
        }

        .email-section__bckgrnd-img {
            width: 100%;
            height: 100%;

            background-repeat: no-repeat;
            background-size: cover;
            background-position: bottom;

            @include mqMobile {
                background-image: url(../../img/512/email-section-bckgrnd-01-512x.jpg);
            }

            @include mqTablet {
                background-image: url(../../img/768/email-section-bckgrnd-01-768x.jpg);
            }
        }
    }

    .email-section__text-container {
        padding: $padding-huge 0;

        @include mqSmallScreen {
            padding: 150px $padding-big 150px $padding-big;
        }

        .email-section__title {
            font-size: 32px;
            color: white;
        }
        .email-section__subtitle {
            color: white;
            font-size: $textsize-lead;
        }

        .email-address-container {
            text-align: center;
            display: block;

            > .email-address {
                color: white;
                font-size: 22px;
                line-height: 100%;
                padding: 0;
                margin: 0;
                height: 32px;
            }
        }

        .email-section__button {
            display: block;
            width: 100%;
            position: relative;
            padding: 12px 0;

            &:hover {
                transform: none;
            }

            > i {
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 36px;
                font-weight: bold;
            }

            span {
                font-size: $textsize-body;
                font-weight: 900;
                text-transform: uppercase;
                padding-left: 18px;
            }
        }
    }
}

// Brand reference section
.brand-ref--section {
    padding: 60px 0;

    .brand-ref__img-container {
        margin-bottom: 20px;

        @include mqMediumScreen {
            margin-bottom: 0;
        }
    }

    .brand-ref__text-container {
        text-align: center;

        @include mqLargeScreen {
            text-align: left;
        }

        h4 {
            margin-bottom: 6px;
        }
        h1 {
            margin-bottom: 12px;
        }
    }
}

// Additional info section
.additional-info--section {
    background-color: rgba(2, 32, 33, 0.05);
    padding: 4em 0;
}

.home-page-sender-form-section {
    padding: 3em 0;
    .focus-border {
        border: 1px solid rgba(0, 0, 0, 0.87) !important;
    }
    .error-message {
        color: red;
    }

    .success-message {
        color: green;
    }

    .MuiCardContent-root {
        padding: 0 !important;
    }
    .form-wrapper {
        margin-top: 30px;

        .center-rb-left-cols {
            display: flex;
            justify-content: center;
        }

        .start-rb-right-cols {
            display: flex;
            justify-content: flex-start;
        }
        .writer-machine {
            max-width: 570px;
            padding: 10px 50px 10px 20px;
        }
        .MuiInputBase-multiline {
            padding: 0 0 0 0;
        }

        .MuiInput-underline {
            &:after {
                border-bottom: none !important;
            }
        }
        .email-form__input {
            &.email-form__textarea {
                padding: 0 !important;
            }
        }

        .email-form textarea {
            padding: 16px 14px;
        }
        .form--subtitle {
            margin-bottom: 25px;
        }

        .form--title {
            max-width: 99%;
        }
        .orientation-center {
            text-align: center;
        }

        .btn-sender-homePage {
            justify-content: flex-end;
            display: flex;
        }

        img {
            max-width: 100%;
            min-height: 100%;
            object-fit: cover;
        }

        .btn {
            min-width: 120px !important;
            margin: 0 0 0 0 !important;
            padding: 0 0 0 0 !important;
        }

        .email-form__button {
            margin: 0 0 0 0 !important;
            padding: 0 20px 20px 20px !important;
        }

        .email-form__input {
            .MuiInput-underline {
                &::before {
                    border-bottom: none !important;
                }

                .form__input-row {
                    textarea {
                        border-color: rgba($color: white, $alpha: 0.25) !important;

                        &::placeholder {
                            font-size: 16px;
                        }

                        &:active {
                            border-color: white;
                        }

                        &:focus {
                            border-color: white;
                        }
                    }
                }
            }
            .MuiCardContent-root {
                padding-top: 0 !important;
                padding-bottom: 0 !important;

                &.Mui-focused {
                    fieldset,
                    textarea {
                        border: none !important;
                        opacity: 0;
                    }
                }
            }
        }
        .btn-sender-homePage {
            background-color: rgba(231, 231, 230, 0.25);
            justify-content: space-between;
        }
        .btn.hero-CTA-homePage {
            display: block;
            margin: $padding-medium auto 0 auto;
            max-width: 120px;

            @include mqSmallScreen {
                margin: $padding-medium 0 0 0;
            }
        }
    }

    .email-form .MuiInputBase-root.Mui-focused {
        textarea {
            border: none !important;
        }
        .textarea-with-button {
            border: 1px solid rgba(0, 0, 0, 0.87);
        }
    }

    .textarea-with-button {
        margin-bottom: 12px;
        border-radius: 5px;
        background-color: rgba(231, 231, 230, 0.25);
        border: 1px solid #d3d3d3;

        &:hover {
            border: 1px solid rgba(0, 0, 0, 0.87);
            input {
                padding: 0 !important;
                margin: 0 !important;
            }
        }
        &:active {
            border: 1px solid rgba(0, 0, 0, 0.87);
        }
        &:focus {
            border: 1px solid rgba(0, 0, 0, 0.87);
        }
    }
    .charCounter {
        color: #d3d3d3;
        line-height: normal;
        vertical-align: bottom;
        width: 100%;
        margin-top: auto;

        .counter-span {
            line-height: normal;
            vertical-align: bottom;
            width: 100%;
            margin-top: auto;
        }
    }
}

.title-wrapper-tickets {
    overflow-y: hidden;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;

    h3 {
        font-size: 25px !important;
        font-weight: 600;
        letter-spacing: 2px;
    }

    .title-1 {
        // border-right: 1px solid white;
        border-bottom: 5px solid #eecf3b;
        width: 100%;
        h3{
            margin-bottom: .2em;
        }
        span{
            font-weight: 500;
        }
    }

    .title-2 {
        border-bottom: 5px solid #eecf3b;
        width: 75%;
    }
}

.title-heights {
    height: 69px;
}

.ticket__addendum {
    font-size: 13px;
}

.square-wrapper {
    margin-top: auto;
    margin-bottom: auto;
    max-width: 5px;
    color: yellow;
    margin-right: 5px;
    margin-left: 5px;
}

.info-border {
    color: color(primary) !important;
    background-color: black;
    border-radius: 50%;
    border: 1px solid black;
}

.fas.fa-arrow-right {
    &.middle {
        font-size: 1.1em;
    }
}

.relative-wrapper {
    position: relative;
}
.bar_pikto {
    width: 38px !important;
    vertical-align: bottom;
    margin-right: 12px;

    &.home-icon-class {
        position: absolute;
        left: 18px;
        top: 25%;
        transform: translate(-50%, -50%);

        @include mqMobileSmall {
            top: 50%;
        }
        @include mqTablet {
            top: 25%;
        }

        @include mqSmallScreen {
            top: 50%;
        }
    }
}

.main-card-padding {
    padding: 30px;
    padding-top: 0;
}
