.single-event--section{
    
    
}

.text__wrapper{
    margin-top:40px;
    }
    
    .text__paragraph{
        font-size: 20px;
    margin-top:20px;
    font-weight: 400;
    color: black;
    }
    h3{
        &.wedding__title{
            font-weight: 700;
            font-size: 3.5em;
        }
        
    }
    h6{
        
    &.main{
        margin-bottom: 0px;
        font-size: 25px;
    }
    
    
    &.thinner{
    font-weight: 400;
    }
    }

    hr{
        &.weeding__hr{
            width: 10.5em;
            border-width: 0.25em;
            border-color: #eecf3b;
            margin: 0;
            margin-top: 5px;
        }
       
    }
 