.curiosities-section{   

    .section-content__wrapper{

        &.anniversary-box{
            .section__text-container{
                text-align: justify;
            }
        }

        .section__img-container{

            margin-bottom: 24px;

            img{
                display: block;
                margin: 0 auto;
                max-width: 250px;
                width: 100%;                
            }
        }

        .section__text-container{
            .section-content__title{
                text-align: center;
                //max-width: 600px;
            }

            .section-content__body-text-container{
                column-count: 1;
                column-gap: 20px;

                @include mqSmallScreen{
                    column-count: 2;
                }
            }
        }
    }
}