// IMPORTS
@import "resets";
@import "abstracts/variables";

@import "typography";
@import "theme-overwrites";
@import "effects";

// Layout
@import "layout/grid";
@import "layout/paddings-margins";
@import "layout/content-layouts";

// General formatting
@import "general-formatting";

// Components
@import "components/images";
@import "components/responsivity";
@import "components/boxed-content";
@import "components/backgrounds";
@import "components/dividers";
@import "components/borders";
@import "components/lists";
@import "components/tables";
@import "components/icons";
@import "components/buttons";
@import "components/sections";
@import "components/hero";
@import "components/functions";
@import "components/form";
@import "components/mui";
@import "components/react-modal-image";
@import "components/_recaptcha";
@import "components/_modal";

// Partials
@import "partials/preloader";
@import "partials/header";
@import "partials/footer";
@import "partials/contact-us-section";

//Pages
@import "pages/home";
@import "pages/history";
@import "pages/events";
@import "pages/404";
@import "pages/subevents";
@import "pages/bar";

.selectLanguageUI {
    label {
        color: white;
    }
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
        color: white;

        input {
            color: white;
        }
    }
}
ul {
    &.accordion.accordion-1 {
        .title {
            font-size: 1.35714286em;
            line-height: 1.36842105em;
            font-weight: 400;
            border-top: 0;
        }
        .active.title.h4.mb-0 {
            border: 0.5px solid black;
            background-color: #eecf3b;
        }
    }
}

.ui.styled.accordion .accordion {
    box-shadow: none;
}

/*-----------------------------------
        09 - ANCHOR OFFSETS
-----------------------------------*/
:target:before {
    content: "";
    display: block;
    height: 100px;
    margin: -100px 0 0;
}

.contact-form {
    padding: 1em 0;
    max-width: 450px;
}

/*MAIN CTA SECTION*/

.main-cta--section {
    padding: 8em 0;
}

.main-cta--section:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    background-position: center;
    background-size: cover;
    -webkit-filter: grayscale(100%) brightness(35%);
    filter: grayscale(100%) brightness(35%);
    opacity: 0.2;
}

.main-cta--section .main-cta-container {
    padding-top: 2em;
}

.back-to-top.sc-bdVaJa.fWohKN {
    font-size: 15px;
    position: fixed;
    width: 3.71428571em;
    height: 3.71428571em;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    //right: 1.85714286em;
    bottom: 1.71428571em;
    padding-top: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
    z-index: 99;
    border: 1px solid #ececec;
    transition: 0.2s ease-out;
    -webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;

    &:hover {
        transform: translate3d(0, -5px, 0);
        -webkit-transform: translate3d(0, -5px, 0);
    }
    i {
        color: #252525;
    }
}

.ui.styled.accordion .accordion .title,
.ui.styled.accordion .title {
    color: rgba(0, 0, 0, 0.6);
    opacity:0.65;
    -webkit-transition: 0.3s linear;

    &.active{
    opacity:1;
    }
    &:hover{
    opacity: 1
    }
}
.ui.styled.accordion {
    box-shadow: none;

    .title.h4.mb-0 {
        span {
            font-weight: 400;
            font-size: 1.35714286em;
            line-height: 1.36842105em;
            display: contents;
        }
        border-radius: 6px;
        border: 1px solid black;
        box-shadow: 0 0 20px rgba(197, 197, 197, 0.15);
        position: relative;
        padding: 0.46428571em 0.92857143em;
    display: flex;
    }

    .title.h4.mb-0:hover {
        &.active {
            transform: scale(1);
            box-shadow: 0 0 20px rgba(197, 197, 197, 0.15);
        }
        //box-shadow: 0 0 20px rgba(2, 32, 33, 0.1);
        //transform: scale(1.01);
    }
}

svg {
    &.MuiSvgIcon-root.MuiSelect-icon {
        color: white;
    }
}

.toEventsBtnWrapper {
    display: flex;
    justify-content: flex-end;
}

.ui.accordion .accordion .active.content, .ui.accordion .active.content{
padding-bottom: 0;
padding-top: 15px;
padding-left: 0;
}

.ui.styled.accordion .accordion .title, .ui.styled.accordion .title{
    padding: .5em 0;
}

.ui.accordion .accordion .title .dropdown.icon, .ui.accordion .title .dropdown.icon{
    margin: auto 0;
  //  margin-right: 10px;
}


.flex-test-parent{
justify-content: center;
height:100%;
padding-top:0 !important;
padding-bottom: 0 !important;



.flex-test{
    width:100%;
display:flex;
flex-direction: row;



&.descriptons{
    height:100%;
    }




&.subtitle-table{
display:flex;
flex-direction: column;
}
span{
    text-align: right;
    font-size: 1.2em;
    font-weight: bolder;
    color: #252525;
    width:100%;
    text-align: center;
    padding-left: 10px;

    &.add-span-text{
font-size:.8em;
}
}


.title-type{
    
    border-right: 1px solid #ececec;
    min-width:50%;
    .text-orientation{
    text-align: left;
   // padding-left: 35px;
    }
    @include mqMobileBig {
        min-width:33.5%;
        }
    
}
}
}

.subtitle-test{
line-height: .7em;
}

.flex-test-container{
display: flex;
border: 1px solid #ececec;
-webkit-box-shadow: 0 0 20px rgba(2, 32, 33, 0.05);
box-shadow: 0 0 20px rgba(2, 32, 33, 0.05);
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;

&.mondSunContainer{
border:0
}
.time-row{
    font-size:12px;
    line-height: 15px;
    position: relative;
    top: -4px;
    margin:0 10px;
    text-align: center;
    }

.boxed-wo-bottom-padding{
margin-bottom:0 !important;
}
}

.padding-bottom-test{
padding-bottom: 50px;
}

.max-width-test-center{
padding:0 1rem;

}



.text-type{
width:100%;
display:flex;



p{
color:color(dark);
margin-top: auto;
    margin-bottom: auto;
    margin-left:10px;
}

.important-info{
        font-weight: 600;
        margin-left:0;
}
.svg-wrapper{

    &.info-section{
    margin-top:0;
    }
padding-left:10px;
//padding-right: 10px;
margin-top: auto;
    margin-bottom: auto;

i{
color:color(dark);
}
}
}
.text-container-tickets-section{
    align-items: center;
    display: flex;
}
.subtitle-span{
    font-size: 0.9em;
    font-weight: bolder;
    color: #252525;
    width: 100%;
    text-align: center;

    @include mqMobileMedium{
        font-size: 1.2em;
    }
}

.flex-space-between{
width:100%;
display:flex;
justify-content: space-between;
align-items: flex-end;
padding: 5px 0;

.more-children{
    margin-bottom:0 !important;
    font-weight: 400;
    color:color(dark);
    margin-top: auto;
    margin-bottom: auto !important;
}

.ticket__price-span{
    text-align: right;
    font-size: 1.1em;
    font-weight: 600;
    margin: 0;
    padding: 0;

}
}

.max-height-cl{
    max-height: 560px;
    display: flex;
    justify-content: center;
}

.machine-sys{
    @include tinyScreen{
    height: 600px;
    }
    
}

.pdf-page{
height: 100vh;
}

.fireworkText{
    max-width: 900px;
    text-align: left;
    border-right: 1px solid  black;
    font-size: 18px !important;
margin-bottom: 0 ;
    @media only screen and (max-width: 765px) { 
        border:none;  
        margin-bottom: 15px !important;   

      }

}