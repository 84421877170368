

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.demoPage.stf__item.--hard.--left.--simple{
  border-right: 0;
  box-shadow: inset -7px 0 30px -7px rgb(20 10 3 / 20%), -2px 0 5px 2px rgb(0 0 0 / 20%);
}


.demoPage.stf__item.--right.--hard{
  box-shadow: inset 0 0 30px 0 rgb(36 10 3 / 50%), -2px 0 5px 2px rgb(0 0 0 / 40%);
}

.demoPage.stf__item.--soft.--right.--simple{
border-left: 0;
    box-shadow: inset -7px 0 30px -7px rgb(20 10 3 / 20%), -2px 0 5px 2px rgb(0 0 0 / 20%);
}

.demoPage.stf__item.--soft.--left.--simple{
  border-right: 0;
  box-shadow: inset -7px 0 30px -7px rgb(20 10 3 / 20%), -2px 0 5px 2px rgb(0 0 0 / 20%);
}

.stf__block{
  box-shadow: inset -7px 0 30px -7px rgb(20 10 3 / 20%), -2px 0 5px 2px rgb(0 0 0 / 25%);

}