// Generic
.hero-section {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: stretch;

    @include mqTablet {
        min-height: 650px;
        flex-direction: column;
        justify-content: flex-end;

        .container {
            padding-right: 0;
            padding-left: 0;
        }
    }

    @include mqSmallScreen {
        padding-bottom: $padding-huge;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .filter--black {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);

        &.filter--black--gradient {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.5) 150%);
        }
    }
    

    //Site modifiers
    &.hero-section--rendezvenyek {
        &::before {
            background-image: url("../../img/480/kisfaludy-480-45.jpg");

            @include mqMobileBig {
                background-image: url("../../img/512/kisfaludy-512-45.jpg");
            }

            @include mqTablet {
                background-image: url("../../img/768/kisfaludy-768-45.jpg");
            }

            @include mqSmallScreen {
                background-image: url("../../img/992/kisfaludy-992-45.jpg");
            }

            @include mqMediumScreen {
                background-image: url("../../img/1024/kisfaludy-1024-45.jpg");
            }

            @include mqLargeScreen {
                background-image: url("../../img/1280/kisfaludy-1280-45.jpg");
            }

            @include mqXLScreen {
                background-image: url("../../img/1680/kisfaludy-1680-45.jpg");
            }

            @include mqXXLScreen {
                background-image: url("../../img/1920/kisfaludy-1920-45.jpg");
            }
        }
    }

    &.hero-section--foto {
        &::before {
            background-image: url("../../img/events/foto/foto-intro-hd.jpg");

            @include mqMobileBig {
                background-image: url("../../img/events/foto/foto-intro-mobile.jpg");
            }

            @include mqTablet {
                background-image: url("../../img/events/foto/foto-intro-tablet.jpg");
            }

            @include mqSmallScreen {
                background-image: url("../../img/events/foto/foto-intro-tablet.jpg");
            }
            
            @include mqMediumScreen {
                background-image: url("../../img/events/foto/foto-intro-smallscreen.jpg");
            }

            @include mqLargeScreen {
                background-image: url("../../img/events/foto/foto-intro-smallscreen.jpg");
            }

            @include mqXLScreen {
                background-image: url("../../img/events/foto/foto-intro-smallscreen.jpg");
            }

            @include mqXXLScreen {
                background-image: url("../../img/events/foto/foto-intro-largescreen.jpg");
            }
        }
    }

    &.hero-section--tortenelem {
        &::before {
            background-position: bottom;
            background-image: url("../../img/480/kisfaludy-480-28.jpg");

            @include mqMobileBig {
                background-image: url("../../img/512/kisfaludy-512-28.jpg");
            }

            @include mqTablet {
                background-image: url("../../img/768/kisfaludy-768-28.jpg");
            }

            @include mqSmallScreen {
                background-image: url("../../img/992/kisfaludy-992-28.jpg");
            }

            @include mqMediumScreen {
                background-image: url("../../img/1024/kisfaludy-1024-28.jpg");
            }

            @include mqLargeScreen {
                background-image: url("../../img/1280/kisfaludy-1280-28.jpg");
            }

            @include mqXLScreen {
                background-image: url("../../img/1680/kisfaludy-1680-28.jpg");
            }

            @include mqXXLScreen {
                background-image: url("../../img/1920/kisfaludy-1920-28.jpg");
            }
        }
    }

    &.hero-section--bar {
        &::before {
            background-position: 25% 75%;
            background-image: url("../../img/bar-page/koktelok_013_hero.jpg");
            
            @include mqMobile {
                background-position: 45% 15%;
                background-image: url("../../img/bar-page/koktelok_013_hero.jpg");
            }
            

            @include mqMobileBig {
                background-image: url("../../img/bar-page/koktelok_013_hero.jpg");
            }
            @include mqTablet {
                background-image: url("../../img/bar-page/koktelok_013_hero.jpg");
            }
            
            @include mqSmallScreen {
                background-position: 25% 75%;
                background-image: url("../../img/bar-page/koktelok_013_hero.jpg");
            }

            @include mqMediumScreen {
                background-position: 25% 75%;
                background-image: url("../../img/bar-page/koktelok_013_hero.jpg");
            }

            @include mqLargeScreen {
                background-position: 25% 75%;
                background-image: url("../../img/bar-page/koktelok_013_hero.jpg");
            }

        }

        .filter--black.filter--black--gradient{
        opacity:.75;
        }
    }


    &.hero-section--eskuvo {
        .filter--black{
            background-color: rgba(0, 0, 0, 0.2);
            &.filter--black--gradient {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.45) 150%);
            }
        }
        &::before {
            background-position: bottom;
            background-image: url("../../img/480/kisfaludy-eskuvo02.jpg");
            @include mqMobileBig {
                background-image: url("../../img/512/kisfaludy-eskuvo02.jpg");
            }
            @include mqTablet {
                background-image: url("../../img/768/kisfaludy-eskuvo02.jpg");
            }
            
            @include mqSmallScreen {
                background-image: url("../../img/992/kisfaludy-eskuvo02.jpg");
            }

            @include mqMediumScreen {
                background-image: url("../../img/1024/kisfaludy-eskuvo02.jpg");
            }

            @include mqLargeScreen {
                background-image: url("../../img/1280/kisfaludy-eskuvo02.jpg");
            }

            @include mqXLScreen {
                background-image: url("../../img/1680/kisfaludy-eskuvo02.jpg");
            }

            @include mqXXLScreen {
                background-image: url("../../img/1920/kisfaludy-eskuvo02.jpg");
            }
            
        }
    }

    &.hero-section--ceges {
        &::before {
            background-image: url("../../img/480/kisfaludy-480-46.jpg");

            @include mqMobileBig {
                background-image: url("../../img/512/kisfaludy-512-46.jpg");
            }

            @include mqTablet {
                background-image: url("../../img/768/kisfaludy-768-46.jpg");
            }

            @include mqSmallScreen {
                background-image: url("../../img/992/kisfaludy-992-46.jpg");
            }

            @include mqMediumScreen {
                background-image: url("../../img/1024/kisfaludy-1024-46.jpg");
            }

            @include mqLargeScreen {
                background-image: url("../../img/1280/kisfaludy-1280-46.jpg");
            }

            @include mqXLScreen {
                background-image: url("../../img/1680/kisfaludy-1680-46.jpg");
            }

            @include mqXXLScreen {
                background-image: url("../../img/1920/kisfaludy-1920-46.jpg");
            }
        }
    }
    &.hero-section--csaladi {
        &::before {
            background-position: top;
            background-image: url("../../img/events/csaladi/csaladi_intro_mobil.jpg");

            @include mqTablet {
                background-image: url("../../img/events/csaladi/csaladi_intro_tablet.jpg");
            }

            @include mqMediumScreen {
                background-image: url("../../img/events/csaladi/csaladi_intro_bigpic.jpg");
            }
        }
    }
    .hero-section__content {
        height: 100%;
        width: 100%;
        padding-top: 6em;

        @include mqTablet {
            padding-top: 0;
        }

        .hero-section__text {
            color: white;

            .hero-section__title {
                font-size: $textsize-title;
                color: white;
            }

            .hero-section__bodytext {
                font-size: $textsize-lead;
                line-height: 160%;
                font-weight: lighter;

                > p:not(:last-of-type) {
                    margin-bottom: 1em;
                }
            }
        }
    }
}

/*INDEX HERO*/
.hero-wrapper {
    align-self: center;
    justify-content: center;
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    padding: 0;

    &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: 0;
        display: block;
        background-image: url("../../img/480/kisfaludy-480-04.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-filter: sepia(10%) grayscale(100%);
        filter: sepia(10%) grayscale(100%);
        z-index: -20;

        @include mqMobile {
            background-image: url("../../img/480/kisfaludy-480-04.jpg");
        }

        @include mqMobileBig {
            background-image: url("../../img/512/kisfaludy-512-04.jpg");
        }

        @include mqTablet {
            background-image: url("../../img/768/kisfaludy-768-04.jpg");
        }

        @include mqSmallScreen {
            background-image: url("../../img/992/kisfaludy-992-04.jpg");
        }

        @include mqMediumScreen {
            background-image: url("../../img/1024/kisfaludy-1024-04.jpg");
        }

        @include mqLargeScreen {
            background-image: url("../../img/1280/kisfaludy-1280-04.jpg");
        }

        @include mqXLScreen {
            background-image: url("../../img/1680/kisfaludy-1680-04.jpg");
        }

        @include mqXXLScreen {
            background-image: url("../../img/1920/kisfaludy-1920-04.jpg");
        }
    }

    &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: block;
        background-color: black;
        opacity: 0.65;
        z-index: -10;
    }

    .filter {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: block;
        background-image: url("https://www.transparenttextures.com/patterns/fake-brick.png");
        opacity: 1;
        z-index: -5;
    }

    .hero__content {
        padding-top: 5em;
.hero-margin{
    @include mqSmallScreen {
       margin-left:50px;
    }

}
        .hero__text {
            h1,
            p {
                color: white;
                text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.45);
            }

            .hero__title {
                font-size: $textsize-subtitle;
                font-weight: 800;

                @include mqTablet {
                    font-size: $textsize-title-medium;
                }

                @include mqMediumScreen {
                    font-size: $textsize-title-xxl;
                }
            }
            .hero__subtitle {
                font-size: $textsize-body;

                @include mqTablet {
                    font-size: $textsize-lead;
                }
            }
        }
    }
}

.hero-background * {
    -webkit-transform: skewY(0);
    -ms-transform: skewY(0);
    transform: skewY(0);
}