// EFFECTS

    //Box Shadow
    @mixin boxShadowSimple{
            box-shadow: 0 0 12px rgba(0, 0, 0, .3);         
    }



// MEDIA QUERIES

@mixin mediaQuery($args){
    @media screen and (min-width: $args){
        @content;
    }
}


@mixin mqMobile{
    @media screen and (min-width: $breakpoint-mobile){
        @content;
    }
}


@mixin mqMobileSmall{
    @media screen and (min-width: $breakpoint-mobile-small){
        @content;
    }
}

@mixin mqMobileMedium{
    @media screen and (min-width: $breakpoint-mobile-medium){
        @content;
    }
}

@mixin mqMobileBig{
    @media screen and (min-width: $breakpoint-mobile-big){
        @content;
    }
}

@mixin mqTablet{
    @media screen and (min-width: $breakpoint-tablet){
        @content;
    }
}

@mixin tinyScreen{
    @media screen and (min-width: $breakpoint-tiny-screen){
        @content;
    }
}

@mixin mqSmallScreen{
    @media screen and (min-width: $breakpoint-small-screen){
        @content;
    }
}

@mixin mqMediumScreen{
    @media screen and (min-width: $breakpoint-medium-screen){
        @content;
    }
}

@mixin mqXLargeScreen{
    @media screen and (min-width: $breakpoint-xlarge-screen){
        @content;
    }
}

@mixin mqLargeScreen{
    @media screen and (min-width: $breakpoint-large-screen){
        @content;
    }
}

@mixin mqXLScreen{
    @media screen and (min-width: $breakpoint-xl-screen){
        @content;
    }
}

@mixin mqXXLScreen{
    @media screen and (min-width: $breakpoint-xxl-screen){
        @content;
    }
}

@mixin box-shadow($shadow1, $shadow2:false, $shadow3:false, $shadow4:false, $shadow5:false) {
    $params: $shadow1;
     @if $shadow2 
       { $params: $shadow1, $shadow2; }
       @if $shadow3 != false
         { $params: $shadow1, $shadow2, $shadow3; }
         @if $shadow4 != false
           { $params: $shadow1, $shadow2, $shadow3, $shadow4; }
           @if $shadow5 != false
             { $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5; }
   
     -webkit-box-shadow: $params;
        -moz-box-shadow: $params;
             box-shadow: $params;
   
   }