.content-type {
    //TWO COLUMN CONTENT
    &--two-column {
        padding: 32px 0 !important;

        @include mqLargeScreen {
            padding: 80px 0 !important;
        }

        > .content-type__item {
            // restructure code
        }

        > .section {
            &--two-column {
                padding: 0;

                &:not(:first-of-type) {
                    padding-top: 64px;

                    @include mqLargeScreen {
                        padding-top: 120px;
                    }
                }
            }
        }
    }
}