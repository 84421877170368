.modal-header{
background-color: color(dark);
text-align: center;
border-bottom: 5px solid #eecf3b;
.h4{
    width: 100%;
    margin: 0.5em 0;
}
.modal-title-custom{
color:white;
width:100%;
font-weight: 500;
}

.close{
color:white
}


}

.modal-body{
    .paragraph{
        margin:20px;
        font-size:15px;
        }
}

.modal-footer{
.modal-btn{
    margin: 1em;
span{
    font-weight: 500;
font-size:25px;
}
}
}