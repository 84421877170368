.contact-us--section{
    position: relative;

    &::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: none;
        background-size: cover;
        background-position: 50% 65%;
        background-repeat: no-repeat;
        filter: grayscale(1);
        opacity: .15;       

        @include mqSmallScreen{
            background-image: url(../../img/992/kisfaludy-60.jpg);
        }

        @include mqMediumScreen{
            background-image: url(../../img/1024/kisfaludy-60.jpg);
        }

        @include mqLargeScreen{
            background-image: url(../../img/1280/kisfaludy-60.jpg);
        }

        @include mqXLScreen{
            background-image: url(../../img/1680/kisfaludy-60.jpg);
        }

        @include mqXXLScreen{
            background-image: url(../../img/1920/kisfaludy-60.jpg);
        }
    }

    .section__text-block{
        

        @include mqSmallScreen{
            .text-block__title{
                font-size: 40px;
            }
    
            p{
                font-size: 18px;
            }
    
            ul>li{
                font-size: 18px;
            }
        }

        
    }
}